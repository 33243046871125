import { OsImage } from 'types/OsImageList';
import DownloadCard from 'components/Layout/Download/DownloadCard';

interface Props {
  osImagesList: OsImage[];
  headerTitle?: string;
}

const groupOrder = ['current', 'other', 'beta'];

const DownloadOsImages = (props: Props) => {
  const { osImagesList } = props;

  const osImagesGroups = Array.from(
    new Set(osImagesList.map(osImage => osImage.type)),
  );

  return (
    <>
      <div className="download-os-images">
        {groupOrder
          .filter(group => osImagesGroups.includes(group))
          .map(osImageGroup => (
            <ul key={osImageGroup} className="download-os-images__list">
              {osImagesList
                .sort((prev, next) => next.priority - prev.priority)
                .map(
                  osImage =>
                    osImage.type === osImageGroup && (
                      <DownloadCard key={osImage.id} osImage={osImage} />
                    ),
                )}
            </ul>
          ))}
      </div>
    </>
  );
};

export default DownloadOsImages;

// // ukrywamy tymczasowo mirros z uwagi na przeniesienie plików do cloudflare */

//  <a
//       className="download-os-images__list-link link-type-arrow"
//       href="https://download.simplemining.net/images/"
//       title="Images archive"
//       target="_blank"
//       rel="noreferrer"
//     >
//       <span className="download-os-images__list-link-title link-type-arrow-title">
//         {t('downloadInstall.link')}
//       </span>
//       <ArrowCircleIcon />
//     </a>
