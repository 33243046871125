import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { toCamelCase } from 'utils/str';
import { ChevronIcon } from 'images/svg';

const Breadcrumbs = () => {
  const path = usePathname();
  const { t } = useTranslation();

  const segments = path.split('/').filter(Boolean);

  const breadcrumbs = [
    {
      label: t('breadcrumbs.home'),
      href: '/',
    },
    ...segments.map((segment, index) => {
      const href = '/' + segments.slice(0, index + 1).join('/');

      return {
        label: t(`breadcrumbs.${toCamelCase(segment)}`),
        href,
      };
    }),
  ];

  return (
    <nav aria-label="Breadcrumb" className="breadcrumbs">
      <ol className="breadcrumbs__list">
        {breadcrumbs.map((crumb, index) => (
          <li key={index} className="breadcrumbs__list-item">
            {index < breadcrumbs.length - 1 ? (
              <Link href={crumb.href} className="link-type-underline">
                {crumb.label}
              </Link>
            ) : (
              <span
                aria-current="page"
                className="breadcrumbs__list-item--active"
              >
                {crumb.label}
              </span>
            )}
            {index < breadcrumbs.length - 1 && (
              <span className="breadcrumbs__list-item-symbol">
                <ChevronIcon />
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
