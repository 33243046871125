export const toCamelCase = (str: string) => {
  return str
    .toLowerCase()
    .replace(/(?:^.|[-_\s].)/g, (match, index) =>
      index === 0 ? match : match.charAt(match.length - 1).toUpperCase(),
    );
};

export const toSnakeCase = (str: string) => {
  return str
    .replace(/\s+/g, '_')
    .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    .replace(/^_/, '')
    .toLowerCase();
};

export const toKebabCase = (str: string) => {
  return str
    .replace(/\s+/g, '-')
    .replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
    .replace(/^-/, '')
    .toLowerCase();
};
